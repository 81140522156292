import { useTranslation } from 'react-i18next';
import './pill.component.scss';

interface Props {
  variant?: 'default' | 'error' | 'warning';
  resource: ResourceKey;
}

export const Pill = ({ variant, resource }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`${variant || 'default'} pill`}>
      {(variant === 'error' || variant === 'warning') ? <span className="glyphicon glyphicon-warning-sign" /> : null}
      <span>{t(resource)}</span>
    </div>
  );
};
